<template>
  <div
    class="
      grey lighten-3
      fill-height d-flex flex-column align-center justify-center
    "
    :class="{
      'py-10': !isMobile,
    }"
  >
    <v-card
      :tile="isMobile"
      :elevation="6"
      :width="isMobile ? '100%' : maxWidth+'px'"
      :max-height="isMobile ? undefined : ($vuetify.breakpoint.height-80)+'px'"
      class="flex-grow-1 d-flex flex-column"
      style="overflow: auto"
    >
      <v-fade-transition leave-absolute group tag="div" class="primary">
        <v-skeleton-loader
          v-if="loading"
          key="loading"
          tile
          class="px-6 py-8"
          type="text@2"
          width="100%"
          style="margin-top: "
        />
        <div
          v-else-if="!errorStatus"
          key="title"
          class="
            primary white--text
            d-flex flex-column align-center justify-center pa-6
            text-center
          "
          style="min-height: 100px"
        >
          <h3>
            {{ question.form_title || "-" }}
          </h3>
          <h5 v-if="question.form_description">
            {{ question.form_description }}
          </h5>
        </div>
      </v-fade-transition>

      <v-fade-transition appear leave-absolute mode="out-in" group tag="div" class="flex-grow-1">
        <v-skeleton-loader
          key="loading"
          v-if="loading"
          tile
          class="pa-6"
          type="text@2,paragraph,image"
        />

        <div
          key="errorStatus"
          v-else-if="errorStatus"
          class="
            fill-height text-center text-h5
            d-flex flex-column align-center justify-center
            mt-n10
          "
        >
          <div>
            Formulário
          </div>
          <b :class="errorStatus.color + '--text'">
            {{ errorStatus.situation }}
          </b>
        </div>
        
        <div key="formContainer" v-else>
          <template v-if="!tryAgain && !errorStatus">
            <div class="pa-6">
              <b>Solicitante:</b>
              {{ question.accountant.name }}
              <br />
              <b>Cliente:</b>
              {{ question.client.name }}
            </div>

            <v-divider />
          </template>
          <div
            v-if="tryAgain"
            class="py-5 d-flex flex-column justify-center align-center"
            style="width: 100%"
          >
            Erro inesperado ao carregar o formulário.
            <br/>
            <v-btn
              small
              :loading="loading"
              color="error"
              class="mt-2"
              dark
              @click="getCurrentQuestion()"
            >
              tentar novamente
            </v-btn>
          </div>

          <v-form v-else ref="form" class="pa-6" @submit.prevent="sendAnswer()">
            <div class="text-subtitle-1 font-weight-medium">
              {{ requiredOnText(question.title) }}
            </div>
            <div v-if="question.description" class="text-body-2 mt-2 mb-6 text-justify">
              {{ question.description }}
            </div>
            <div v-else class="mb-6" />

            <!-- TEXT -->
            <template v-if="question.answer_type == 'text'">
              <v-text-field
                key="text"
                v-model="answer.text"
                v-bind="fieldAttrs"
                placeholder="Resposta em texto"
              />
            </template>

            <!-- LARGE TEXT -->
            <template v-if="question.answer_type == 'largetext'">
              <v-textarea
                key="largetext"
                v-model="answer.text"
                v-bind="fieldAttrs"
                auto-grow
                :rows="4"
              />
            </template>

            <!-- CPF -->
            <template v-else-if="question.answer_type == 'cpf'">
              <v-text-field
                key="cpf"
                v-model="answer.text"
                v-bind="fieldAttrs"
                label="CPF"
                placeholder="000.000.000-00"
                v-mask="['###.###.###-##']"
                :rules="[...fieldAttrs.rules, $rules.cpf]"
              />
            </template>

            <!-- CNPJ -->
            <template v-else-if="question.answer_type == 'cnpj'">
              <v-text-field
                key="cnpj"
                v-model="answer.text"
                v-bind="fieldAttrs"
                label="CNPJ"
                placeholder="00.000.000/0000-00"
                v-mask="['##.###.###/####-##']"
                :rules="[...fieldAttrs.rules, $rules.cnpj]"
              />
            </template>

            <!-- PHONE -->
            <template v-else-if="question.answer_type == 'phone'">
              <v-text-field
                key="phone"
                v-model="answer.text"
                v-bind="fieldAttrs"
                label="Número de Telefone"
                placeholder="(00) 00000-0000"
                v-mask="['(##) ####-####', '(##) #####-####']"
                :rules="[...fieldAttrs.rules, $rules.phone]"
              />
            </template>

            <!-- MONETARY -->
            <template v-else-if="question.answer_type == 'monetary'">
              <v-text-field
                key="monetary"
                v-model="answer.monetary"
                v-money="loading ? '':$utils.moneyFormat"
                v-bind="fieldAttrs"
                prefix="R$"
              />
            </template>

            <!-- DATE -->
            <template v-else-if="question.answer_type == 'date'">
              <DateField
                label="Data"
                v-model="answer.date"
                :rules="[...fieldAttrs.rules]"
                v-bind="fieldAttrs"
              />
            </template>

            <!-- CHECKBOX -->
            <template v-else-if="question.answer_type == 'checkbox'">
              <v-checkbox
                v-for="(option, i) in question.options" :key="i"
                class="ma-2 pa-0"
                hide-details="auto"
                v-model="option.is_selected"
                @change="validateOptionsMinMax()"
                :label="option.value"
              />
            </template>

            <!-- RADIO -->
            <template v-else-if="question.answer_type == 'radio'">
              <v-radio-group
                v-bind="fieldAttrs"
                v-model="selectedRadio"
              >
                <v-radio
                  v-for="(option, i) in answer.options" :key="i"
                  :value="option.option_order"
                  :label="option.value"
                  v-bind="fieldAttrs"
                />
              </v-radio-group>
            </template>

            <!-- FILE -->
            <template v-else-if="question.answer_type == 'file'">
              <v-btn
                color="success"
                large depressed block
                @click="$refs.filePicker.click()"
                v-bind="fieldAttrs"
                :disabled="loadingFiles"
              >
                Enviar arquivo(s)
              </v-btn>
              <input
                type="file"
                :accept="acceptFileTypes"
                multiple
                ref="filePicker"
                @input="onFilesSelected($event)"
                class="d-none"
              />
              <div class="
                my-6
                d-flex flex-wrap align-center
                text-body-2 font-weight-medium
              ">
                <div class="mr-1">
                  Tipos Permitidos:
                </div>
                <v-chip
                  small label
                  class="mx-1"
                  v-for="(type, i) in allowedTypes"
                  :key="i"
                >
                  {{ type.name }}
                </v-chip>
              </div>
              <v-card :loading="loading">
                <v-card-subtitle class="font-weight-medium grey lighten-2">
                  Arquivos Enviados
                </v-card-subtitle>
                <v-divider />
                <v-list dense class="pa-0" style="height: 200px; overflow: auto;">
                  <v-slide-y-transition group>
                    <template v-for="(document, i) in question.documents">
                      <div :key="i" v-if="!document.deleted">
                        <v-list-item style="height: 50px">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ document.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="!document.id">
                              <div v-if="document.error" class="error--text">
                                Erro ao enviar, clique para tentar novamente
                              </div>
                              <div v-else>
                                Enviando arquivo
                              </div>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action
                            style="height: 50px; width: 50px"
                            class="d-flex align-center justify-center"
                          >
                            <v-fade-transition group leave-absolute mode="out-in">
                              <v-progress-circular
                                key="deleting"
                                v-if="document.deleting"
                                color="error"
                                indeterminate
                              />
                              <v-btn
                                key="delete"
                                v-else-if="document.id"
                                large icon
                                @click="deleteFile(i)"
                                :disabled="loadingDeleteFile"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                              <v-btn
                                key="error"
                                v-else-if="document.error"
                                large icon
                                color="error"
                                @click="sendFile(i)"
                              >
                                <v-icon>mdi-refresh</v-icon>
                              </v-btn>
                              <v-progress-circular
                                key="sending"
                                v-else
                                color="grey darken-1"
                                :indeterminate="!document.progress || document.progress == 100"
                                :value="document.progress"
                              />
                            </v-fade-transition>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider />
                      </div>
                    </template>
                  </v-slide-y-transition>
                </v-list>
              </v-card>
            </template>

            <!-- required min and max -->
            <template v-if="requiredLimits.active">
              <div class="my-4 d-flex flex-column">
                <template
                  v-for="(row, i) in [
                    {var: 'Mínimo', value: question.required_min},
                    {var: 'Máximo', value: question.required_max},
                  ]"
                >
                  <div :key="i" v-if="row.value != undefined" class="mb-2">
                    <v-chip label class="rounded-r-0">
                      {{ row.var }} de {{ requiredLimits.text }}:
                    </v-chip>
                    <v-chip color="grey darken-1" dark label class="rounded-l-0 font-weight-medium">
                      {{ row.value }}
                    </v-chip>
                  </div>
                </template>
              </div>
              <v-slide-y-transition>
                <v-alert
                  v-if="optionsMinError || optionsMaxError"
                  color="warning darken-1 white--text"
                  class="font-weight-medium"
                >
                  {{
                    optionsMinError
                      ? `${requiredLimits.action} pelo menos ${question.required_min} ${requiredLimits.text}`
                      : `${requiredLimits.action} no máximo ${question.required_max}  ${requiredLimits.text}`
                  }}
                </v-alert>
              </v-slide-y-transition>
            </template>

            <!-- SKIP -->
            <v-btn
              v-if="
                !question.required &&
                !['checkbox','file'].includes(question.answer_type)
              "
              color="grey lighten-4 grey--text text--darken-3"
              large depressed block
              class="mt-6"
              @click="skip()"
            >
              Pular pergunta
            </v-btn>
            <div style="height: 70px" />
          </v-form>
        </div>
      </v-fade-transition>

      <div
        v-if="!tryAgain && !errorStatus"
        class="white"
        :style="{
          position: 'fixed',
          top: '100%',
          width: '100%',
          'max-width': maxWidth+'px',
          height: '70px',
          'margin-top': isMobile ? '-70px':'-110px',
          'box-shadow': '0px -5px 10px rgba(0, 0, 0, 0.2)',
        }"
      >
        <div class="d-flex pa-4 align-center">
          <v-chip label>
            {{ question.question_order }} / {{ question.total_questions }}
          </v-chip>
          <v-spacer />
          <v-btn
            v-if="question.question_order > 1"
            text class="mr-3"
            :disabled="loading || loadingSend || loadingFiles"
            @click="previous()"
          >
            Voltar
          </v-btn>
          <v-btn
            class="primary" :elevation="1"
            @click="sendAnswer()"
            :disabled="loading || loadingFiles"
            :loading="loadingSend"
          >
            Continuar
          </v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import DateField from "@/components/core/DateField.vue"

export default {
  name: "FormResponse",

  components: {
    DateField,
  },

  data: () => ({
    loading: false,
    loadingSend: false,
    loadingDeleteFile: false,
    tryAgain: false,

    errorStatus: null,
    
    dateMenu: false,
    
    question: {
      accountant: {},
      client: {},
      documents: [],
    },
    answer: {
      options: null,
      text: null,
      date: null,
      monetary: null,
    },
    sendingFiles: [],
    selectedRadio: null,
    optionsMinError: false,
    optionsMaxError: false,
  }),

  computed: {
    code(){
      return this.$route.params.code;
    },
    maxWidth(){
      return 600;
    },
    isMobile(){
      return this.$vuetify.breakpoint.width <= this.maxWidth;
    },
    fieldAttrs(){
      return {
        filled: true,
        class: "pb-1",
        'hide-details': 'auto',
        disabled: this.loading || this.loadingSend,
        rules: this.question.required ? [this.$rules.required] : [],
      };
    },
    requiredLimits(){
      return {
        active: ['checkbox','file'].includes(this.question.answer_type),
        text: this.question.answer_type == 'checkbox' ? "opções":"arquivo(s)",
        action: this.question.answer_type == 'checkbox' ? "Selecione":"Envie",
      };
    },
    allowedTypes(){
      if (!this.question.allowed_types || this.question.allowed_types.length == 0){
        return [
          {name: "Sem restrição"},
        ];
      }
      return this.question.allowed_types;
    },
    acceptFileTypes() {
      if (this.question.allowed_types) {
        let extensions = [];
        this.question.allowed_types.forEach(allowed_type => {
          extensions = [
            ...extensions,
            ...allowed_type.extensions.map(ext => `.${ext}`),
          ];
        });
        return extensions.join(',');
      }
      return "*";
    },
    loadingFiles(){
      for (let i = 0; i < this.question.documents.length; ++i){
        const doc = this.question.documents[i];
        if (!doc.id || doc.deleting){
          return true;
        }
      }
      return false;
    },
  },

  methods: {
    requiredOnText(txt){
      if (this.question.required){
        txt += " *";
      }
      return txt;
    },
    validateOptionsMinMax(){
      if (this.question.answer_type == 'checkbox'){
        let selectedCount = this.answer.options.filter(o => o.is_selected).length;
        if (this.question.required_min){
          this.optionsMinError = (selectedCount < this.question.required_min);
        }
        if (this.question.required_max){
          this.optionsMaxError = (selectedCount > this.question.required_max);
        }
        return (!this.optionsMinError && !this.optionsMaxError);
      }
      return true;
    },
    validateFilesMinMax(){
      if (this.question.answer_type == 'file'){
        let sentCount = this.question.documents
              .filter(doc => doc.id && !doc.deleted)
              .length;
        if (this.question.required_min){
          this.optionsMinError = (sentCount < this.question.required_min);
        }
        if (this.question.required_max){
          this.optionsMaxError = (sentCount > this.question.required_max);
        }
        return (!this.optionsMinError && !this.optionsMaxError);
      }
      return true;
    },
    removeExtension(fileName) {
      let index = fileName.lastIndexOf(".");
      return fileName.substring(0, index);
    },
    onFilesSelected(e) {
      try {
        const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
        files.forEach((file) => {
          let splitted = file.name.split('.');
          const extension = splitted[splitted.length-1].toLowerCase();
          if (this.acceptFileTypes != '*' && !this.acceptFileTypes.includes(extension)){
            this.$showMessage('warning', `O tipo arquivo "${file.name}" não é permitido`);
          } else {
            this.question.documents.push({
              file,
              name: this.removeExtension(file.name),
              progress: undefined,
              error: false,
              deleting: false,
              deleted: false,
            });
            this.sendFile(this.question.documents.length-1);
          }
        });
        this.$refs.filePicker.value = "";
      } catch (error){
        this.$showMessage('error', "Erro inesperado ao enviar arquivo(s) " + error);
      }
    },
    deleteFile(index){
      this.$confirmDialog(
        `Deletar o arquivo ${this.question.documents[index].name}?`,
        'O arquivo será deletado permanentemente.',
        () => new Promise((resolve, reject) => {
          const id = this.question.documents[index].id;
          this.question.documents[index].deleting = true;
          this.$set(this.question.documents[index], 'deleting', true);
          this.$axios.delete(`/forms/${this.code}/remove-file/${id}`)
            .then(() => {
              resolve();
              this.$set(this.question.documents[index], 'deleted', true);
            })
            .catch(() => {
              reject();
              this.$showMessage('error', "Erro ao deletar arquivo");
            })
            .finally(() => {
              this.validateFilesMinMax();
              this.$set(this.question.documents[index], 'deleting', false);
            })
        }),
      );
    },
    sendFile(index) {
      this.$set(this.question.documents[index], 'error', false);
      this.$set(this.question.documents[index], 'progress', undefined);
      const document = this.question.documents[index];
      let formData = new FormData();
      formData.append("file", document.file);
      formData.append("name", document.name);
      this.$axios.post(
        `/forms/${this.code}/send-file`,
        formData,
        {
          onUploadProgress: (event) => {
            let progress = 100 * (event.loaded / event.total);
            this.$set(this.question.documents[index], 'progress', progress);
          },
        }
      )
        .then((response) => {
          this.$set(this.question.documents[index], 'id', response.data);
        })
        .catch((error) => {
          console.log("error", error);
          if ((error.response || {}).status == 406){
            this.$showMessage("error", "Tipo do Arquivo não permitido");
          } else {
            this.$showMessage("error", "Erro ao enviar o documento");
          }
          this.$set(this.question.documents[index], 'error', true);
        })
        .finally(() => {
          this.validateFilesMinMax();
          this.$set(this.question.documents[index], 'error', true);
        });
    },
    previous() {
      this.loading = true;
      this.$axios
        .patch(`/forms/${this.code}/return`)
        .then(() => {
          this.getCurrentQuestion();
        })
        .catch((error) => {
          console.log(error);
          this.$showMessage("error", "Erro voltar pergunta");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    skip() {
      this.loading = true;
      this.$axios
        .patch(`/forms/${this.code}/skip`)
        .then(() => {
          this.getCurrentQuestion();
        })
        .catch((error) => {
          console.log(error);
          if ((error.response || {}).status == 401) {
            this.$showMessage("warning", "Essa questão é obrigatória");
          } else {
            this.$showMessage("error", "Erro ao pular o pergunta");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCurrentQuestion() {
      this.loading = true;
      this.$axios
        .get(`/forms/${this.code}/current_question`)
        .then((response) => {
          this.question = {
            ...response.data,
            documents: (response.data.documents || []).map(d => ({
              ...d,
              deleting: false,
              deleted: false,
            }))
          };
          this.answer = {
            text: this.question.response_text,
            date: this.$utils.formatDate(this.question.response_date),
            monetary: this.$utils.formatMoney(this.question.response_monetary),
            doc_id: (this.question.document || {}).id,
            file_name: (this.question.document || {}).name,
            file_description: (this.question.document || {}).description,
            file: null,
            options: this.question.options,
          };
          this.selectedRadio = (this.question.options.filter(
            option => option.is_selected)[0] || {}
          ).option_order;
          this.tryAgain = false;
          this.errorStatus = null;
          if (this.$refs.form){
            this.$refs.form.resetValidation();
            this.optionsMinError = false;
            this.optionsMaxError = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.question = {
            accountant: {},
            client: {},
          };
          switch ((error.response || {}).status) {
            case 423:
              this.errorStatus = { situation: "Cancelado", color: "error" };
              return;
            case 404:
              this.errorStatus = { situation: "Não Encontrado", color: "warning" };
              return;
            case 406:
              this.errorStatus = { situation: "Expirado", color: "warning" };
              return;
            case 410:
              this.errorStatus = { situation: "Respondido", color: "green" };
              return;
          }
          this.$showMessage("error", "Erro ao carregar o formulário");
          this.tryAgain = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sendAnswer() {
      if (
        !this.$refs.form.validate() ||
        !this.validateOptionsMinMax() ||
        !this.validateFilesMinMax()
      ){
        this.$showMessage("warning", "Preencha os campos corretamente");
        return;
      }
      this.loadingSend = true;
      this.$axios
        .post(
          `/forms/${this.code}/send-answer`,
          {
            ...this.answer,
            date: this.$utils.fromBrDatetime(this.answer.date),
            monetary: this.$utils.formattedMoneyToDecimal(this.answer.monetary),
            options: this.question.answer_type != 'radio' ?
              this.answer.options
            : this.question.options.map((option) => ({
                ...option,
                is_selected: (option.option_order == this.selectedRadio),
              }))
          },
        )
        .then(() => {
          this.getCurrentQuestion();
        })
        .catch((error) => {
          console.log(error);
          if ((error.response || {}).status == 406){
            this.$showMessage("error", "Preencha a resposta corretamente");
            return;
          }
          this.$showMessage("error", "Erro ao enviar a resposta");
        })
        .finally(() => {
          this.loadingSend = false;
        });
    },
  },

  mounted() {
    this.getCurrentQuestion();
  },
};
</script>
